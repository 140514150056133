import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { CurrencyType, GiftDonateType, GiftType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, fetchRecords, postFormData } from "../../utils/AppUtil";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';
import { error } from "console";

type ModalProps = {
    onGiftCreated: () => void;
}

const giftAdditionals = {
    amounts: [0, 0, 0, 5],
    backgrounds: ['flowers', 'stone', 'candle', 'wreath']
}

export const ModalDonateGift: React.FC<ModalProps> = ({onGiftCreated}) => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Refs
    const refs = useRef<(HTMLDivElement | null)[]>([]);

    // States
    const [formStep, setFormStep] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalFail, setModalFail] = useState<string>("");
    const [formProgressHide, setFormProgressionHide] = useState<string>("");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);
    
    const [giftTiles, setGiftTiles] = useState<React.ReactElement[]>();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [selectedGift, setSelectedGift] = useState<string>("");

    const [currency, setCurrency] = useState<string>('USD');

    const [charactersRemaining, setCharactersRemaining] = useState<number>(240);
    const [charactersRemainingText, setCharactersRemainingText] = useState<string>(" characters max");

    const [loggedInUser, setLoggedInUser] = useState<Boolean>(false);

    const txtAreaRef = useRef<HTMLTextAreaElement>(null!);


    // Initial formData values
    const formData: GiftDonateType = {
        giftTypeId: "",
        message: "",
        paymentProviderId: "",
        currencyId: "",
        anonymous: false
    }

    // Set formState to formData
    const [formState, setFormState] = useState<GiftDonateType>(formData);

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (show: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        // Define a ref to store the starting X position of the touch
        const startX = useRef<number | null>(null);
    
        useEffect(() => {
            const handleTouchStart = (e: TouchEvent) => {
                if (modalRef.current) {
                    startX.current = e.touches[0].clientX;
                }
            };
    
            const handleTouchMove = (e: TouchEvent) => {
                if (modalRef.current && startX.current !== null) {
                    const modal = modalRef.current;
                    const touchX = e.touches[0].clientX;
                    const deltaX = startX.current - touchX;
    
                    const canScrollLeft = modal.scrollLeft > 0;
                    const canScrollRight = modal.scrollWidth > modal.clientWidth + modal.scrollLeft;
    
                    if (modal.contains(e.target as Node)) {
                        // Scrolling inside the modalRef div
                        if ((deltaX > 0 && !canScrollRight) || (deltaX < 0 && !canScrollLeft)) {
                            e.preventDefault(); // Prevent scrolling outside the modalRef div
                        }
                    } else {
                        // Scrolling outside the modalRef div
                        e.preventDefault(); // Prevent scrolling the entire modal
                    }
    
                    startX.current = touchX; // Update startX for continuous movement
                }
            };
    
            if (show) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchstart', handleTouchStart, { passive: true });
                window.addEventListener('touchmove', handleTouchMove, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            }
    
            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            };
        }, [show, modalRef]); // Dependencies for the useEffect hook
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    // Fetch currencies from API
    useEffect(() => {
        fetchRecords(commonData.token, 'currency', processCurrencyData);
    }, [activeIndex]);

    // Process currency data
    const processCurrencyData = (responseJson: CurrencyType) => {
        const getCurrencyId = responseJson.find(id => id.name === currency);
        const setCurrencyId = getCurrencyId?.currencyId;

        setFormState(() => ({
            ...formState,
            currencyId: setCurrencyId
        }));

        console.log('CURRENCY Response:', responseJson);
        console.log('CURRENCY Get:', getCurrencyId);
        console.log('CURRENCY Set:', setCurrencyId);
    }

    // Fetch gift data from API
    useEffect(() => {
        fetchRecords(commonData.token, 'gift/type', processGiftData);
    }, [activeIndex]);
    
    // Process gift data
    const processGiftData = (responseJson: GiftType[]) => {
        setGiftTiles(buildGiftTiles(responseJson));
    }
    
    // Build gift tiles JSX
    const buildGiftTiles = (data: GiftType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
    
        data.forEach((gift: GiftType, index: number) => {
            const giftCurrency = gift.prices.find(price => price.currency.name === currency);
            const imageName: string = gift.name.toLowerCase();

            result.push(
                <div
                    key={index}
                    ref={el => (refs.current[index] = el)}
                    onClick={() => handleTileClick(index, gift.giftTypeId, gift.name, gift.isFree)} // Use the function that captures the correct index
                    className={`donate-gift-tile ${activeIndex === index ? 'gift-active' : ''}`}
                    style={{
                        background: `linear-gradient(180deg, rgba(26, 50, 60, 0) 0%, #1A323C 100%), url('/images/tombstone-background-${imageName}.png')`,
                        backgroundPosition: activeIndex === index ? 'center top' : 'center'
                    }}
                >
                    <div className={`gift-tile-select ${activeIndex === index ? 'selected' : ''}`}>
                        <img src="/images/check-tick.svg" alt="" />
                    </div>
    
                    <div className={`gift-tile-text ${activeIndex === index ? 'selected' : ''}`}>
                        <div className="d-flex justify-content-center align-items-center" style={{height: "100px"}}>
                            <img src={`/images/tombstone-${imageName}.svg`} className="gift-image" />
                        </div>

                        <p className="gift-name">{gift.name}</p>

                        {gift.isFree ?
                            <p className="gift-cost">Free</p>
                            :
                            <p className="gift-cost">{giftCurrency?.currency.symbol}{giftCurrency?.price}</p>
                        }
                    </div>
                </div>
            );
        });
    
        return result;
    };

    // Set modal background to memorial cover image
    const backgroundImg: string = `${ASSET_URL}/public/picture/${memorialData.coverPictureId}`
    let backgroundStyle;
    if (backgroundImg !== null) {
        backgroundStyle = {backgroundImage: `url(${backgroundImg})`};
    }

    // Set gift type when clicking tile
    const handleTileClick = (index: number, giftId: string, giftName: string, isFree: boolean) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
        setSelectedGift(giftName.toLowerCase());

        if (isFree) {
            setFormState(() => ({
                ...formState,
                giftTypeId: giftId,
                currencyId: null
            }));
        } else {
            setFormState(() => ({
                ...formState,
                giftTypeId: giftId
            }));
        }
    }

    // On modal show
    const handleShow = () => {
        setShow(true);
        setFormStep(0);
        setActiveIndex(null);
        setFormState(formData);
        setFormProgressionHide("");
    }

    // On modal hide
    const handleClose = () => {
        setShow(false);
        setTimeout(() => {
            setFormStep(0);
            setActiveIndex(null);
            setFormState(formData);
            setFormProgressionHide("");
        }, 100);
    }

    // Skip - clear 'message' and progress to next step
    const handleSkip = () => {
        setFormState(() => ({
            ...formState,
            message: ""
        }));
        handleClickNext();
    }

    // Progress to next step
    const handleClickNext = () => {
        if (activeIndex !== null) {
            if (formStep === (loggedInUser ? 2 : 3)) {
                setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
            }
            setFormStep(formStep + 1);
        }
    }

    // Progress to previous step
    const handleClickBack = () => {
        setFormStep(formStep - 1);
        
        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    // Set formState on input updates
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        if (e.target.name === "message") {
            const value = e.target.value;

            if (value.length <= 240) {
                setCharactersRemaining(240 - value.length);

                if (value.length < 240) {
                    setCharactersRemainingText(" characters remaining");
                } else {
                    setCharactersRemainingText(" characters max");
                }

                setFormState(() => ({
                    ...formState,
                    [e.target.name]: e.target.value
                }));
            }
        }
    }

    // Post data to the server
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            `memorial/${memorialData.memorialId}/gift`,
            formState,
            (response: any) => {
                setModalSuccess(true);
                console.log("handlePost SUCCESS Response:", JSON.stringify(response));
            },
            (error: any) => {
                setModalSuccess(false);
                setModalFail(JSON.stringify(error));
                console.log("handlePost FAILED Response:", JSON.stringify(error));
            }
        );

        handleClickNext();
        onGiftCreated();

        setTimeout(() => {
            handleClose();
        }, 2000); 
    }

    const inputs = document.querySelectorAll('textarea[data-expandable]');

    if (inputs) {
        inputs.forEach((textarea, i) => {
            textarea.addEventListener('input', function(e) {

                const textAreaInput = e.target as HTMLTextAreaElement;
                textAreaInput.style.removeProperty('height');
                textAreaInput.style.height = (textAreaInput.scrollHeight+2) + 'px';
            });
        });
    }

    return (
        <>
            {/* Modal trigger - components/Modals/Tombstone.tsx */}
            <div className="btn-light" onClick={handleShow}>
                <div className="btn-txt">{ t("Tombstone.placeStone", {ns: "components"}) }</div>
            </div>

            {/* Modal content */}
            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>

                <Modal.Header>
                    {formStep === 0 ?
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClose}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                        :
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                    }
                    <p className="modal-title">Place a gift</p>
                    <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                </Modal.Header>

                <Modal.Body>
                    <div className={`form-progress form-progress-absolute ${formProgressHide}`}>
                        <img src={`/images/fl-progress-gift.svg`} className={`form-progress-current form-progress-${formStep}`} alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />

                        {!loggedInUser &&
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        }
                    </div>

                    <div className="form-container">

                        {/* Step 0 - choose gift */}
                        <div className={`form-screen form-content px-3 form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2">
                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Place a gift</p>
                                    <p>Please select a gift below to place on {memorialData.firstName}’s memorial.</p>
                                </div>

                                <div className="donate-leaf-tiles px-3" ref={modalRef}>
                                    {giftTiles}
                                </div>
                            </div>
                        </div>

                        {/* Step 1 - add message */}
                        <div className={`form-screen form-content px-3 form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="form-floating">
                                <textarea data-expandable ref={txtAreaRef} id="message" name="message" value={formState.message} onChange={handleFormChange} maxLength={240} className="form-control gift-message-input" placeholder="Write a message to go with your gift." />
                                <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                            </div>
                        </div>

                        {!loggedInUser &&
                            <div className={`form-screen form-content px-3 form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                                <div className="d-flex flex-column gap-2 mb-4">
                                    <p className="modal-heading">Let them know who this gift is from</p>
                                    <p></p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input id="firstName" name="firstName" type="text" className="form-control" placeholder="Enter your own first name" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input id="lastName" name="lastName" type="text" className="form-control" placeholder="Enter your own last name" />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input id="email" name="email" type="email" className="form-control" placeholder="name@domain.com" />
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {/* Step 2 - gift summary */}
                        <div className={`form-screen form-content px-3 form-content-${formStep === (loggedInUser ? 2 : 3) ? 'current' : (formStep < (loggedInUser ? 2 : 3) ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2 h-100">
                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Gift Summary</p>
                                </div>

                                <div className="gift-summary">
                                    <div className="gift-image-wrapper">
                                        <img src={`/images/tombstone-${selectedGift}.svg`} />
                                    </div>
                                    <div>
                                        <p className="gift-message fs-16 mb-2"><b>Your message</b></p>
                                        <p className="gift-message">{formState.message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Step 3 - success / fail */}
                        <div className={`form-screen form-content px-3 form-content-${formStep === (loggedInUser ? 3 : 4) ? 'current' : (formStep < (loggedInUser ? 3 : 4) ? 'prev' : 'next')}`}>
                            <div className="d-flex flex-column justify-content-center h-100">
                                
                                <div className="d-flex flex-column gap-3 align-items-center justify-content-center mb-5">
                                    {modalSuccess ?
                                        <>
                                            <img src="/images/fl-login-tick.svg" />
                                            <div className="login-success-txt d-flex flex-column gap-1">
                                                <p>Gift placed</p>
                                                <p>Thank you for your kindness.</p>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <img src="/images/fl-icon-error.svg" />
                                            <div className="login-success-txt d-flex flex-column gap-1">
                                                <p>Something went wrong</p>
                                                <p>Please try again</p>
                                            </div>
                                        </>
                                    }
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {formStep === 0 &&
                        <button className="btn btn-modal-footer" onClick={handleClickNext}>Continue</button>
                    }

                    {formStep === 1 &&
                        <div className="d-flex w-100 gap-2">
                            <button className="btn btn-modal-footer dark" onClick={handleSkip}>Skip</button>
                            <button className="btn btn-modal-footer" onClick={handleClickNext}>Continue</button>
                        </div>
                    }

                    {!loggedInUser && formStep === 2 &&
                        <button className="btn btn-modal-footer" onClick={handleClickNext}>Continue</button>
                    }

                    {formStep === (loggedInUser ? 2 : 3) &&
                        <>
                            <button className="btn btn-modal-footer" onClick={handlePost}>Place gift</button>
                            <div className="modal-footer-links d-flex justify-content-center gap-3 pt-4 pb-2 m-0 w-100">
                                <p>Privacy Policy</p>
                                <div className="modal-link-separator" />
                                <p>Terms & Conditions</p>
                            </div>
                        </>
                    }

                </Modal.Footer>
            </Modal>
        </>
    );
}





