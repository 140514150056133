import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { ModalManageRoles } from "./ModalManageRoles";

export const ModalAccountDrawer: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [creator, setCreator] = useState<string>(memorialData.creator.firstname + " " + memorialData.creator.lastname)
    
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);

    useEffect(() => {
        if (commonData.userId === memorialData.creator.userId) {
            setCreator('You');
        }
    }, [commonData, memorialData])

    const handleShow = () => {
        setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        setTimeout(() => {
            setShow(false);
        }, 300);
    }

    const handleModalClose = () => {
        setTimeout(() => {
            handleClose();
        }, 500);
    }

    return (
        <>
            <div className="btn-wrapper" onClick={handleShow}>
                <img src="/images/fl-elips.svg" />
            </div>

            <Modal className={`${modalAnimation} p-0`} show={show} onHide={handleClose}>
                <Modal.Header className="" onClick={handleClose}>
                    <div className="modal-account-drawer-close"></div>
                </Modal.Header>
                
                <Modal.Body className="d-flex flex-column p-0">

                    <div className="account-drawer-txt d-flex flex-column gap-1 px-3 mb-3">
                        <p>This memorial was created by</p>
                        <p>{creator}</p>
                    </div>

                    {(memorialData.role === 'CREATOR' || memorialData.role === 'ADMIN')  &&
                        <>
                            <div className="memorial-account-link">
                                <img src="images/fl-icon-pen.svg" width={24} height={24} />
                                <p>Edit memorial information</p>
                            </div>

                            <ModalManageRoles endpoint={""} modalClose={handleModalClose} />

                            <div className="memorial-account-link">
                                <img src="images/icon-trash.svg" width={24} height={24} />
                                <p>Remove memorial</p>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}





