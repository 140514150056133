import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"

type ModalProps = {
    relativeTo: string
    handleAddRelative: (relative: string) => void;
}

export const AddRelativePopup: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Modal states
    const [show, setShow] = useState(false);
    const [popupAnimation, setPopupAnimation] = useState<string>('');

    useEffect(() => {

    }, [])

    // On modal show
    const handleShow = () => {
        setShow(true);
        setPopupAnimation('animate__animated animate__slideInUp');
    }

    // On modal hide
    const handleClose = () => {
        setPopupAnimation('animate__animated animate__slideOutDown');
        setTimeout(() => {
            setShow(false);
        }, 1000);
    }

    // Select relative
    const handleSelectRelative = (relative: string) => {
        props.handleAddRelative(relative);
        handleClose();
    }

    return (
        <>
            <div className="btn-add-wrapper" onClick={handleShow}>
                <img src="images/fl-plus-lg.svg" />
            </div>

            <Modal className="modal-add-relative" show={show} animation={false} backdrop onHide={handleClose}>
                <Modal.Body>
                    <div className={`add-relative-popup ${popupAnimation}`}>
                        <div className="popup-close" onClick={handleClose}><div /></div>

                        <p className="add-relative-txt">Add a relative to <span>{props.relativeTo}</span></p>

                        <div className="select-relation">
                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Father')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Father
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Mother')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Mother
                                </div>
                            </div>

                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Brother')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Brother
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Sister')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Sister
                                </div>
                            </div>

                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Son')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Son
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Daughter')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Daughter
                                </div>
                            </div>

                            <div className="select-relative-row">
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Partner')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Partner
                                </div>
                                <div className="select-relative-pill" onClick={() => handleSelectRelative('Pet')}>
                                    <div className="btn-add-wrapper pill">
                                        <img src="images/fl-plus-lg.svg" />
                                    </div>
                                    Pet
                                    <img src="images/dog-paw-icon.svg" className="btn-end" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}