import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import { ASSET_URL } from "../../utils/AppUtil";
import { Carousel, Modal } from "react-bootstrap";

type MemoryProps = {
    memorySelected: boolean,
    memoryClicked: number,
    title: string,
    message: string,
    images: { pictureId: string, isVideo: boolean }[] | undefined,
    placeholder: boolean

    showModal: boolean;
    onHide: () => void;
}

export const MemoryDetailed: React.FC<MemoryProps> = ({onHide, showModal, ...props}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext,
    const { commonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData } = useContext(MemorialContext)!;

    // Images array state
    const [videoDurations, setVideoDurations] = useState<number[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [activeClass, setActiveClass] = useState<string>('');

    const videoRefs = useRef<HTMLVideoElement[]>([]);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const firstBarRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    const memorySongs: string[] = [
        "evening-glow-beautiful-piano-music-for-videos",
        "faded-relaxing-piano-music",
        "idea-10",
        "wedding-piano-music-a-thousand-years",
        "afterglow-atmospheric-cinematic-piano",
        "calm-and-peaceful"
    ]

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (showModal) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }

        // Select a new random song when modal opens
        const randomSongIndex = Math.floor(Math.random() * memorySongs.length);

        if (audioRef.current) {
            audioRef.current.src = `/audio/${memorySongs[randomSongIndex]}.mp3`;
            audioRef.current.play();
            audioRef.current.loop = true;
            setIsPlaying(true);  // Set to playing when modal opens
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [showModal]);

    // Verify images are not undefined
    useEffect(() => {
        if (props.images) {
            setVideoDurations(new Array(props.images.length).fill(5000)); // Default to 5000 ms
        }

        setActiveIndex(-1);
        setTimeout(() => {
            setActiveIndex(0);
        }, 20);
        
    }, [props.images]);

    useEffect(() => {
        setActiveClass('');
        setTimeout(() => {
            setActiveClass(Math.random() > 0.5 ? "active-in" : "active-out");
        }, 20);

    }, [activeIndex]);

    const handleClose = () => {
        onHide();
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);  // Reset to paused when modal closes
        }
    }

    const handleAudioPlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    }

    let profileImgSrc = '/images/fl-avatar-min.svg'

    if (memorialData.memorialPictureId) {
        profileImgSrc = `${ASSET_URL}/public/picture/${memorialData.memorialPictureId}`;
    }

    const handleVideoMetadataLoaded = (index: number, duration: number) => {
        setVideoDurations((prevDurations) => {
            const newDurations = [...prevDurations];
            newDurations[index] = duration * 1000; // Convert seconds to milliseconds
            return newDurations;
        });
    };

    const handleSelect = (selectedIndex: number) => {
        // Pause all videos
        videoRefs.current.forEach((video, index) => {
            if (video) {
                video.pause(); // Pause all videos
                video.currentTime = 0; // Reset to the beginning
            }
        });

        // Play the active video if it is a video
        const activeVideo = videoRefs.current[selectedIndex];
        if (activeVideo) {
            activeVideo.play();
        }
    };

	const buildCarouselJsx = (images: { pictureId: string, isVideo: boolean }[] | undefined): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
    
        if (images && images.length > 0) {
            images.forEach((image, index) => {
    
                result.push(
                    image.isVideo ? (
                        <Carousel.Item key={index} interval={videoDurations[index]} onSelect={() => handleSelect(index)}>
                            <video
                                ref={(el) => (videoRefs.current[index] = el!)}
                                autoPlay
                                muted
                                loop
                                playsInline
                                onLoadedMetadata={(e) => handleVideoMetadataLoaded(index, e.currentTarget.duration)}
                            >
                                <source src={`${ASSET_URL}/public/picture/${image.pictureId}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Carousel.Item>
                    ) : (
                        <Carousel.Item key={index}>
                            <img className={`memory-img ${activeClass}`} src={`${ASSET_URL}/public/picture/${image.pictureId}`} alt={`Slide ${index + 1}`} />
                        </Carousel.Item>
                    )
                );
            });
        }
    
        return result;
    };

    const buildCarouselIndicatorsJsx = (images: { pictureId: string, isVideo: boolean }[] | undefined, activeIndex: number, transitionDuration: number): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
    
        if (images && images.length > 0) {
            images.forEach((image, index) => {
                const isActive = index === activeIndex;
                const isCompleted = index < activeIndex;
    
                const progressStyle = {
                    width: isActive ? '100%' : '0',
                    backgroundColor: '#FFFFFF',
                    transition: isActive ? `width ${transitionDuration}ms linear` : 'none',
                }

                const progressBackgroundStyle = {
                    backgroundColor: isCompleted ? '#FFFFFF' : '#0000004D'
                }
    
                result.push(
                    <div key={index} className="memory-bar" style={progressBackgroundStyle}>
                        <div ref={index === 0 ? firstBarRef : null} className="progress" style={progressStyle}></div>
                    </div>
                );
            });
        }
    
        return result;
    }

    return (
        <Modal show={showModal} fullscreen={true} onHide={handleClose}>
            <audio ref={audioRef} />

            <div className="memory-gradient-top"></div>
            <div className="memory-gradient-bottom"></div>

            <div className="memory-content-top">
                <div className="d-flex align-items-center gap-3">
                    <img src={profileImgSrc} className="memory-profile-img" width={50} height={50} style={{borderRadius: '50%', border: '3px solid #FFFFFF', boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)'}} />
                    <div className="memory-name d-flex flex-column">
                        <p>
                            {memorialData.firstName} {memorialData.lastName} &nbsp;
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: '2px'}}>
                                <path d="M1 9L5 5L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </p>
                        <p>from {commonData.firstName} {commonData.lastName}</p>
                    </div>
                </div>

                <div className="btn-modal-close" onClick={handleClose} style={{zIndex: 999, top: '24px'}}>
                    <img src="/images/modal-close-x.svg"/>
                </div>
            </div>

            <div className="memory-content-bottom p-4">
                <div className="memory-text d-flex flex-column gap-3 pe-3">
                    <p>{props.title}</p>
                    <p style={{maxHeight: "150px", overflow: "auto"}}>{props.message}</p>
                </div>
                <div className="d-flex flex-column justify-content-end gap-3">

                    <div className="memory-btn-wrapper">
                        <img src="/images/fl-icon-heart-thin.svg" />
                    </div>

                    <div className="memory-btn-wrapper" onClick={handleAudioPlayPause}>
                        <img src={`/images/fl-icon-audio${!isPlaying ? '-x' : ''}.svg`} />
                    </div>

                </div>
            </div>

            <div className="memory-images-background">
                <div className="memory-bars">
                    {buildCarouselIndicatorsJsx(props.images, activeIndex, videoDurations[activeIndex])}
                </div>
                <Carousel
                    className="memory-carousel"
                    activeIndex={activeIndex}
                    interval={5000}
                    slide={false}
                    onSelect={(selectedIndex) => {
                        handleSelect(selectedIndex); // Call handleSelect to manage video playback
                        setActiveIndex(selectedIndex); // Update the active index
                    }}
                >
                    {buildCarouselJsx(props.images)}
                </Carousel>
            </div>
        </Modal>
    );
}