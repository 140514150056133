import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import { useNavigate } from "react-router-dom";
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import "animate.css"
import { addRelativeType } from "../AppType";
import { AddRelativePopup } from "./AddRelativePopup";
import { DeceasedPlaceholderPopup } from "./DeceasedPlaceholderPopup";
import { LivingPlaceholderPopup } from "./LivingPlaceholderPopup";

export const ModalFamilyTree: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>("modal-deactivate-scroll");
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalSuccessAnimation, setModalSuccessAnimation] = useState<string>('animate__animated animate__slideInRight');

    const [deceasedRelativePopupOpen, setDeceasedRelativePopupOpen] = useState<boolean>(false);
    const [livingRelativePopupOpen, setLivingRelativePopupOpen] = useState<boolean>(false);

    const [addRelativePopup, setAddRelativePopup] = useState<boolean>(false);
    const [selectedRelative, setSelectedRelative] = useState<string>('');
    const [newRelative, setNewRelative] = useState<string>('');
    const [selectedTileName, setSelectedTileName] = useState<string>('');
    const [tileSelected, setTileSelected] = useState<boolean>(false);
    const [hasPassed, setHasPassed] = useState<boolean>(false);

    const [tileSelectedAnimation, setTileSelectedAnimation] = useState<string>('')

    const [popupAnimation, setPopupAnimation] = useState<string>('');
    const [formStep, setFormStep] = useState<number>(0);

    const transformWrapperRef = useRef<ReactZoomPanPinchRef | null>(null);

    const [pointerEvents, setPointerEvents] = useState<'all' | 'none'>('all');

    // State to keep track of the active element's ID
    const [activeId, setActiveId] = useState<string | null>(null);

    const formData: addRelativeType = {
        firstName: "",
        lastName: "",
        passed: false
    }
    const [formState, setFormState] = useState<addRelativeType>(formData);

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);

    useEffect(() => {
    }, []);

    // Disable DOM zooming to allow pinching on image
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleClickBack = () => {
        if (formStep !== 0) {
            setFormStep(formStep - 1);
        } else {
            handleClose();
        }        
    }

    const handleTileClick = (e: any, name: string) => {
        e.preventDefault();
        setSelectedTileName(name);
        setTileSelectedAnimation('animate__animated animate__fadeIn animate__faster');
        setTileSelected(true);
    }

    const handleCloseSelectedTile = () => {
        setTileSelectedAnimation('animate__animated animate__fadeOut animate__faster');

        setTimeout(() => {
            setTileSelected(false);
        }, 300);
    }

    const pointerEventsStyle: CSSProperties = {pointerEvents};

    const handlePanning = () => {
        setPointerEvents('none');
    }

    const handlePanningStop = () => {
        setPointerEvents('all');
    }

    const toggleAddRelative = () => {
        if (addRelativePopup) {
            setPopupAnimation('animate__animated animate__slideOutDown')
            setTimeout(() => {
                setAddRelativePopup(current => !current);
            }, 1000);
        } else {
            setPopupAnimation('animate__animated animate__slideInUp');
            setAddRelativePopup(current => !current);
        }
    }

    const handleAddRelative = (relation: string) => {
        setSelectedRelative(relation);
        setNewRelative(relation);
        setTimeout(() => {
            setFormStep(1);
        }, 750);
    }

    const handleFormChange = (e: React.ChangeEvent<any>) => {
        if (e.target.type === "text") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }
    }

    const handleHasPassed = (passed: boolean) => {
        setHasPassed(passed);

        setFormState((formState) => ({
            ...formState, 
            'passed': passed
        }));
    }

    const handleConfirmRelative = () => {
        setModalSuccess(true);

        setTimeout(() => {
            setFormStep(0);
            setSelectedRelative('');
        }, 1000);

        setTimeout(() => {
            setModalSuccessAnimation('animate__animated animate__slideOutRight');
        }, 2000);

        setTimeout(() => {
            setModalSuccess(false);
            setModalSuccessAnimation('');
            setDeceasedRelativePopupOpen(true);
        }, 3000);
    }

    return (
        <>
            <div className="btn fl-btn-dark w-100 mt-2" onClick={handleShow}>Start</div>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <div className={`btn-modal-back`} onClick={handleClickBack}>
                        <img src="/images/modal-back-chev.svg" />
                    </div>

                    <p className="modal-title">Heading</p>
                    
                    <div className={`btn btn-modal-close`} onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body className="p-0">

                    <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')} justify-content-center`}>

                        {/* Wrapper for panning & zooming */}
                        <TransformWrapper ref={transformWrapperRef} onPanning={handlePanning} onPanningStop={handlePanningStop} centerOnInit={true} doubleClick={{disabled: true}}>
                            <TransformComponent>

                                {/* Main tree wrapper + disable dom zooming */}
                                <div id="deactivateZoom" className="family-tree-wrapper">

                                    <div className="d-flex">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-column align-items-center">
                                                <AddRelativePopup relativeTo={"Father"} handleAddRelative={handleAddRelative} />
                                                <div className="tree-join-y short" />
                                            </div>
                                            
                                            <div className="tile tile-parent" style={{opacity: activeId !== null && (activeId !== 'fatherExtParent' && activeId !== 'fatherExtSibling') ? 0.2 : 1}}>
                                                <div className="text-center w-100 h-100" onClick={(e) => handleTileClick(e, 'Father')} style={{alignContent: "center", pointerEvents}}>
                                                    <p className="tile-name add-relative">Add</p>
                                                    <p className="tile-name add-relative">Father</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column justify-content-end align-items-center">
                                            <div className="d-flex">
                                                <div className="tree-join-x short" />
                                                <div className="tree-join-x short" />
                                            </div>
                                            <div className="tree-join-y long" />
                                        </div>


                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-column align-items-center">
                                                <AddRelativePopup relativeTo={"Mother"} handleAddRelative={handleAddRelative} />
                                                <div className="tree-join-y short" />
                                            </div>
                                            
                                            <div className="tile tile-parent" style={{opacity: activeId !== null && (activeId !== 'motherExtParent' && activeId !== 'motherExtSibling') ? 0.2 : 1}}>
                                                <div className="text-center w-100 h-100" onClick={(e) => handleTileClick(e, 'Mother')} style={{alignContent: "center", pointerEvents}}>
                                                    <p className="tile-name add-relative">Add</p>
                                                    <p className="tile-name add-relative">Mother</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="d-flex">
                                        <div id="origin" className="tile tile-origin user" style={{opacity: activeId !== null && (activeId !== 'originExtSiblingLeft' && activeId !== 'originExtSiblingRight' && activeId !== 'partnerExtParentLeft' && activeId !== 'partnerExtParentRight') ? 0.2 : 1}}>
                                            <div className="d-flex flex-column align-items-center gap-2">
                                                <img src="/images/headshot-placeholder.svg" />
                                                <p className="tile-name">You</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="d-flex flex-column align-items-center">
                                        <div className="tree-join-y short" />
                                        <AddRelativePopup relativeTo={"You"} handleAddRelative={handleAddRelative} />
                                    </div>

                                    <div className="d-flex">

                                    </div>

                                </div>
                            </TransformComponent>
                        </TransformWrapper>

                    </div>

                    <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')} p-4`}>
                        
                        {selectedRelative !== '' &&
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Tell us about your {selectedRelative}</p>
                                </div>

                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">First name</label>
                                        <input id="firstName" name="firstName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their first name" required />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">Last name</label>
                                        <input id="lastName" name="lastName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their last name" required />
                                    </div>

                                    <label htmlFor="email" className="form-label">Have they passed away?</label>
                                    <div className="d-flex gap-3">
                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(true)}>
                                            <div className={`create-memorial-radio ${hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>Yes</p>
                                        </div>

                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(false)}>
                                            <div className={`create-memorial-radio ${!hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>No</p>
                                        </div>
                                    </div>

                                    <button onClick={handleConfirmRelative} className="btn fl-btn-modal-bottom button-absolute">Done</button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>

                {modalSuccess && 
                    <div className={`modal-success-div ${modalSuccessAnimation}`} style={{zIndex: 999}}>
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{formState.firstName} has been added</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                }

                {tileSelected &&
                    <div className={`family-tree-tile-view ${tileSelectedAnimation}`}>
                        <div className={`btn btn-modal-close`} onClick={handleCloseSelectedTile} style={{zIndex: 999}} >
                            <img src="/images/modal-close-x.svg"/>
                        </div>

                        <div className="selected-tile-wrapper">
                            <div className="background-text">{selectedTileName}</div>

                            <div className="selected-tile">
                                <div className="d-flex flex-column align-items-center gap-2">
                                    <img src="/images/headshot-placeholder.svg" />
                                    <p className="tile-name">{selectedTileName}</p>
                                </div>
                            </div>
                        </div>

                        <div className="selected-tile-link">
                            <p>Create {selectedTileName}'s memorial</p>
                            <img src="/images/fl-icon-create-memorial.svg" />
                        </div>
                        <div className="selected-tile-link">
                            <p>Link an existing memorial</p>
                            <img src="/images/fl-icon-connect-memorial.svg" />
                        </div>
                        <div className="selected-tile-link">
                            <p>Invide {selectedTileName}</p>
                            <img src="/images/fl-icon-send.svg" />
                        </div>
                        <div className="selected-tile-link">
                            <p>Add a relative to {selectedTileName}</p>
                            <img src="/images/fl-plus-lg.svg" width={24} height={24} />
                        </div>

                        <div className="selected-tile-bottom-pills">
                            <div className="selected-tile-pill edit">
                                <img src="/images/pill-icon-pen.svg" />
                                <p>Edit relationships</p>
                            </div>

                            <div className="selected-tile-pill remove">
                                <img src="/images/pill-icon-trash.svg" />
                                <p>Remove from tree</p>
                            </div>
                        </div>
                    </div>
                }

                <DeceasedPlaceholderPopup relative={selectedRelative} showModal={deceasedRelativePopupOpen} />
                <LivingPlaceholderPopup relative={selectedRelative} showModal={livingRelativePopupOpen} />

            </Modal>
        </>
    );
}