import React, { useContext, useEffect, useRef, useState } from "react";
import AvatarEditor from 'react-avatar-editor';
import imageCompression from "browser-image-compression";
import { AppContext } from "../../AppContext";
import { MemoryCreateType, PictureUploadType } from "../AppType";
import { postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { useSpring } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import 'animate.css';

const useGesture = createUseGesture([dragAction, pinchAction]);

type ModalProps = {
    triggerMemory: (value: string) => void;
    onFileUpload: (status: boolean, croppingStatus: boolean, count: number, total: number, error: Error | null) => void;
    onModalHide: () => void;
}

export const CreateMemory: React.FC<ModalProps> = ({triggerMemory, onFileUpload, onModalHide}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Cropping & zooming refs
    const pinchRef = useRef<HTMLDivElement>(null!)
    const editorRef = useRef<AvatarEditor>(null!);
    const pictureInputRef = useRef<HTMLInputElement>(null!);
    const txtAreaRef = useRef<HTMLTextAreaElement>(null!);
    const txtAreaFinalRef = useRef<HTMLTextAreaElement>(null!);

    // Form progression
    const [formSuccess, setFormSuccess] = useState<string>("");
    const [formStep, setFormStep] = useState<number>(0);
    // 0 = Title
    // 1 = Message
    // 2 = Media options
    // 3 = Add media
    // 4 = Final screen

    // Gallary & Editor states
    const [formStateImages, setFormStateImages] = useState<string[]>([]);
    const [newImage, setNewImage] = useState<File>();
    const [newImageArray, setNewImageArray] = useState<File[]>([]);
    const [zoom, setZoom] = useState<number>(1);
    const [addPhotos, setAddPhotos] = useState<boolean>(false);
    const [addVideos, setAddVideos] = useState<boolean>(false);
    const [finalEdit, setFinalEdit] = useState<boolean>(false);
    const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);
    const [uploadCount, setUploadCount] = useState<number>(0);
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);

    // Video compression
    const [loaded, setLoaded] = useState(false);
    const ffmpegRef = useRef(new FFmpeg());

    // Modal states
    const [animation, setAnimation] = useState<string>("animate__animated animate__flipInY");
    const [charactersRemaining, setCharactersRemaining] = useState<number>(240);
    const [charactersRemainingText, setCharactersRemainingText] = useState<string>(" characters max");
    const [btnNext, setBtnNext] = useState<boolean>(true);
    const [btnNextAnimation, setBtnNextAnimation] = useState<string>("");
    const [btnSkip, setBtnSkip] = useState<boolean>(false);
    const [btnSkipAnimation, setBtnSkipAnimation] = useState<string>("");
    const [formProgressHide, setFormProgressionHide] = useState<string>("");

    const [canvasWidth, setCanvasWidth] = useState<number>(window.innerWidth);
    const [canvasHeight, setCanvasHeight] = useState<number>(window.innerHeight - 240);

    // formState for loading image to gallery
    const initForm: PictureUploadType = {
        memorialId: memorialData.memorialId,
        file: null
    }
    const [formState, setFormState] = useState<PictureUploadType>(initForm);

    // formState for the memory being created
    const initFormMemory: MemoryCreateType = {
        title: "",
        message: "",
        pictureIds: [],
        extra: {
            additionalProp1: {},
            additionalProp2: {},
            additionalProp3: {}
        }
    }
    const [formStateMemory, setFormStateMemory] = useState<MemoryCreateType>(initFormMemory);

    // Initial settings for touch-zoom functionality
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: zoom,
        rotateZ: 0,
    }));

    // Initialize FFmpeg globally or in a context where it is accessible
    const ffmpeg = new FFmpeg(); // Assuming you are initializing FFmpeg elsewhere

    // Load FFmpeg function
    const loadFFmpeg = async () => {
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        await ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        });
    };


    // useEffect to prevent default event behaviour for touch actions on component mount
    useEffect(() => {
        const handler = (e: Event) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);

        // return runs cleanup on unmount to remove event listeners
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);


    // Build image array
    const buildImageArray = (): React.ReactNode[] => {
        return newImageArray.map((file, index) => {
    
            const fileSrc = URL.createObjectURL(file);
            let newFile: React.ReactElement | null = null;
    
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                newFile = (
                    <div key={`new-${index}`} className={`gallery-image-wrapper final-screen ${file === newImage ? 'selected' : ''}`} style={{backgroundImage: `url(${fileSrc})`}}>                        
                        <img src="/images/fl-btn-cross.svg" className="event-image-remove" width={24} height={24} onClick={() => handleImageRemove(file)} />
                    </div>
                );

            } else if (file.type === 'video/mp4') {
                newFile = (
                    <div key={`new-${index}`} className="gallery-video-wrapper final-screen video">
                        <img src="/images/fl-btn-cross.svg" className="event-image-remove" width={24} height={24} onClick={() => handleImageRemove(file)} />
                        <video src={fileSrc} width="100%" height="100%" />
                    </div>
                );
            }
    
            return newFile; 
        }).filter(Boolean); // Filters out any null or undefined values
    };

    // remove image from array
    const handleImageRemove = (file: File) => {
        const itemIndex = newImageArray.indexOf(file);

        if (itemIndex > -1) {

            // Create a new array with the item removed
            const updatedArray = [...newImageArray];
            updatedArray.splice(itemIndex, 1);

            // Update the state with the new array
            setNewImageArray(updatedArray);
            setNewImage(undefined);
        }
    }

    // Scroll zoom for pc users
    const scaleStep = 0.1;
    document.getElementById('selectedImageContainer')?.addEventListener('wheel', (event) => {
        event.preventDefault();
        
        if (event.deltaY < 0) {
            setZoom(zoom + scaleStep);
        } else {
            setZoom(zoom - scaleStep);
        };
    });

    // Deactivate DOM zooming to allow uninterupted image zooming
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    // Touch-pinch-zoom actions
    useGesture(
        {
            onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
                if (pinching) return cancel();
                api.start({ x, y });
            },
            onPinch: ({offset: [s], first}) => {
                if (first) {
                    const {width, height, x, y} = pinchRef.current!.getBoundingClientRect();
                    const tx = x + width / 2;
                    const ty = y + height / 2;
                    api.start({ x: -tx, y: -ty });
                }
                setZoom(s);
            },
        },
        {
            target: pinchRef,
            drag: {from: () => [style.x.get(), style.y.get()]},
            pinch: {scaleBounds: {min: 0.25, max: 5}, rubberband: false},
        }
    );

    // Form change
    const handleFormChange = (e: React.ChangeEvent<any>) => {

        if (e.target.name === "message") {
            const value = e.target.value;

            if (value.length <= 240) {
                setCharactersRemaining(240 - value.length);

                if (value.length < 240) {
                    setCharactersRemainingText(" characters remaining");
                } else {
                    setCharactersRemainingText(" characters max");
                }

                setFormStateMemory(() => ({
                    ...formStateMemory,
                    [e.target.name]: e.target.value
                }));
            }
        }

        if (e.target.name === "title") {
            setFormStateMemory(() => ({
                ...formStateMemory,
                [e.target.name]: e.target.value
            }));
        }

        if (e.target.type === "file" && e.target.files) {
            const filesArray: File[] = Array.from(e.target.files);
            setNewImageArray(newImageArray => [...newImageArray, ...filesArray]);
        }
    }

    // Navigate form forwards
    const handleClickNext = () => {
        if (formStep === 1) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnNext(false);
                setBtnSkip(true);
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
        }

        if (formStep === 3) {
            setFormStateMemory((formStateMemory) => ({
                ...formStateMemory,
                pictureIds: formStateImages
            }));
        }
        setFormStep(formStep + 1);
    }

    // Navigate form backwards
    const handleClickBack = () => {
        if (formStep === 2) {
            setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnSkip(false);
                setBtnNext(true);
                setBtnNextAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
            setFormStep(formStep - 1);

        } else if (formStep === 3) {
            setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            setBtnSkip(true);
            setFormProgressionHide("");
            setFormStep(formStep - 1);

        } else if (formStep === 4) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setAddPhotos(false);
            setAddVideos(false);
            setFormProgressionHide("");

            setTimeout(() => {
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
                setBtnNext(false);
                setBtnSkip(true);
            }, 500);
            setFormStep(2);
        } else {
            setFormStep(formStep - 1);
        }

        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    // Skip adding media - go to final screen
    const handleSkip = () => {
        setAddPhotos(false);
        setAddVideos(false);
        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
            setBtnSkip(false);
            setFormStep(3);
        }, 250);
    }

    // Closing modal or clicking back from formStep = 0
    const handleClickBackClose = () => {
        setAnimation("animate__animated animate__flipOutY");
        triggerMemory("animate__animated animate__flipInY");
    }

    const handleAddImage = () => {
        setAddPhotos(true);

        if (formStep === 4) {
            setFormStep(formStep - 1);
        }
        
        pictureInputRef.current.click();
    }

    // Add images media option selected
    const handleAddAssets = () => {
        setAddVideos(false);
        setAddPhotos(true);

        setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
        setFormStep(3);

        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setBtnSkip(false);
        }, 500);
    }

    // Truncate title
    const truncate = (text: string) => {
        return text.length > 30 ? text.substring(0, 30) + "..." : text;
    }

    // Activate final screen input fields
    const handleFinalEdit = () => {
        setFinalEdit(c => !c);

        if (!finalEdit) {
            setTimeout(() => {
                txtAreaFinalRef.current.style.removeProperty('height');
                txtAreaFinalRef.current.style.height = (txtAreaFinalRef.current.scrollHeight+2) + 'px';
            }, 0);
        }
    }

    // Open croping for selected image
    const handleClickCrop = (file: File, index: number) => {
        setZoom(1);
        setNewImage(file);
        setActiveImageIndex(index);
    }

    // const handleCrop = async (): Promise<void> => {
    //     if (activeImageIndex === null) {
    //         console.error("No active image selected for cropping.");
    //         return;
    //     }
      
    //     const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
        
    //     return new Promise((resolve, reject) => {
    //         canvasImage.toBlob((blob: Blob | null) => {
    //             if (!blob) {
    //                 reject(new Error("Failed to convert canvas to Blob"));
    //                 return;
    //             }
      
    //             // Replace the file at the activeImageIndex
    //             setNewImageArray(prevArray => {
    //                 if (activeImageIndex >= 0 && activeImageIndex < prevArray.length) {
    //                     const updatedArray = [...prevArray];
    //                     updatedArray[activeImageIndex] = new File([blob], prevArray[activeImageIndex].name, { type: 'image/jpeg' });
    //                     return updatedArray;
    //                 } else {
    //                     console.error("Invalid active image index.");
    //                     return prevArray;
    //                 }
    //             });
      
    //             resolve();
    //             setNewImage(undefined);
    //         });
    //     });
    // };

    const handleCrop = async (): Promise<void> => {
        if (activeImageIndex === null) {
            console.error("No active image selected for cropping.");
            return;
        }
    
        const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
        const ctx = canvasImage.getContext('2d');
    
        if (ctx) {
            // Save the image data before clearing the canvas
            const imageData = ctx.getImageData(0, 0, canvasImage.width, canvasImage.height);
    
            // Clear the canvas to remove the white background
            ctx.clearRect(0, 0, canvasImage.width, canvasImage.height);
    
            // Redraw the image data on the cleared canvas
            ctx.putImageData(imageData, 0, 0);
    
            // Optionally, crop the canvas to the visible area of the image
            const croppedCanvas = cropToVisibleArea(canvasImage, ctx);
    
            return new Promise((resolve, reject) => {
                croppedCanvas.toBlob((blob: Blob | null) => {
                    if (!blob) {
                        reject(new Error("Failed to convert canvas to Blob"));
                        return;
                    }
    
                    // Replace the file at the activeImageIndex
                    setNewImageArray(prevArray => {
                        if (activeImageIndex >= 0 && activeImageIndex < prevArray.length) {
                            const updatedArray = [...prevArray];
                            updatedArray[activeImageIndex] = new File([blob], prevArray[activeImageIndex].name, { type: 'image/png' });
                            return updatedArray;
                        } else {
                            console.error("Invalid active image index.");
                            return prevArray;
                        }
                    });
    
                    resolve();
                    setNewImage(undefined);
                }, 'image/png');
            });
        } else {
            console.error("Failed to get canvas context.");
        }
    };
    
    const cropToVisibleArea = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const { width, height } = canvas;
    
        let top = 0, bottom = height, left = 0, right = width;
    
        // Find top boundary
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const alpha = imageData.data[(y * width + x) * 4 + 3];
                if (alpha !== 0) {
                    top = y;
                    break;
                }
            }
            if (top > 0) break;
        }
    
        // Find bottom boundary
        for (let y = height - 1; y >= 0; y--) {
            for (let x = 0; x < width; x++) {
                const alpha = imageData.data[(y * width + x) * 4 + 3];
                if (alpha !== 0) {
                    bottom = y + 1;
                    break;
                }
            }
            if (bottom < height) break;
        }
    
        // Find left boundary
        for (let x = 0; x < width; x++) {
            for (let y = top; y < bottom; y++) {
                const alpha = imageData.data[(y * width + x) * 4 + 3];
                if (alpha !== 0) {
                    left = x;
                    break;
                }
            }
            if (left > 0) break;
        }
    
        // Find right boundary
        for (let x = width - 1; x >= 0; x--) {
            for (let y = top; y < bottom; y++) {
                const alpha = imageData.data[(y * width + x) * 4 + 3];
                if (alpha !== 0) {
                    right = x + 1;
                    break;
                }
            }
            if (right < width) break;
        }
    
        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = right - left;
        croppedCanvas.height = bottom - top;
    
        const croppedCtx = croppedCanvas.getContext('2d');
        if (croppedCtx) {
            croppedCtx.putImageData(ctx.getImageData(left, top, right - left, bottom - top), 0, 0);
        }
    
        return croppedCanvas;
    };

    // Handle image compression
    const handleImageCompression = async (file: File): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob);
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };

        try {

            // Increment progress count
            setUploadCount(prevCount => {
                const newCount = prevCount + 1;
                onFileUpload(true, false, newCount, newImageArray.length, null); // Update parent with new count
                return newCount;
            });

            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

            // Simulate delay
            // await new Promise(resolve => setTimeout(resolve, 1000));

            return compressedFile;
        } catch (error: any) {
            console.log(error.message);
            throw error;
        }
    };

    // Video compression function
    const handleVideoCompression = async (file: File): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob);
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    
        try {

            // Increment progress count
            setUploadCount(prevCount => {
                const newCount = prevCount + 1;
                onFileUpload(true, false, newCount, newImageArray.length, null); // Update parent with new count
                return newCount;
            });

            // Ensure FFmpeg is loaded
            if (!ffmpeg.loaded) {
                console.log('Loading FFmpeg...');
                await loadFFmpeg();
            }
    
            // Write the file to FFmpeg's virtual file system
            console.log('Writing file to FFmpeg...');
            await ffmpeg.writeFile(file.name, await fetchFile(URL.createObjectURL(file)));
    
            // Set up logging
            ffmpeg.on('log', ({ message }) => {
                console.log('FFmpeg log:', message);
            });
    
            // Run the FFmpeg command to compress the video
            // Adjust CRF for compression level
            // Optional: Add -vf scale=WIDTH:HEIGHT to resize video
            console.log('Running FFmpeg command...');
            await ffmpeg.exec([
                '-i', file.name,
                '-vcodec', 'libx264', 
                '-preset', 'ultrafast',   // Try faster or ultrafast preset
                '-crf', '51',          // Adjust CRF value here
                '-b:v', '500k',        // Optional: Set bitrate, e.g., 1 Mbps
                // '-vf', 'scale=640:-1', // Optional: Resize video, width 640 and maintain aspect ratio
                `compressed_${file.name}`
            ]);
    
            // Read the compressed output file
            console.log('Reading compressed file...');
            const data = await ffmpeg.readFile(`compressed_${file.name}`);
            const compressedBlob = new Blob([data], { type: 'video/mp4' });
    
            console.log('compressedFile instanceof Blob', compressedBlob instanceof Blob);
            console.log(`compressedFile size ${compressedBlob.size / 1024 / 1024} MB`);
    
            return compressedBlob;
        } catch (error: any) {
            console.error('Error during video compression:', error.message);
            throw error;
        }
    };

    const handlePostPicture = async (compressedFile: Blob): Promise<string | null> => {
        try {
            const formData = new FormData();
            formData.set("memorialId", formState.memorialId);
            formData.set("file", compressedFile);
    
            return new Promise((resolve, reject) => {
                postFormPictureFormData(
                    commonData.token,
                    'picture',
                    formData,
                    (responseData: any) => {
                        console.log("handlePostPicture Success", responseData);
                        const { pictureId } = responseData;
                        resolve(pictureId);
                    },
                    (error: any) => {
                        console.log("handlePostPicture Fail", error);
                        reject(null);
                    }
                );
            });
        } catch (error) {
            console.error('Error in handlePostPicture:', error);
            return null;
        }
    };

    const handlePostVideo = async (compressedVideo: Blob): Promise<string | null> => {
        try {
            const formData = new FormData();
            formData.set("memorialId", formState.memorialId);
            formData.set("file", compressedVideo);
    
            return new Promise((resolve, reject) => {
                postFormPictureFormData(
                    commonData.token,
                    'picture',
                    formData,
                    (responseData: any) => {
                        console.log("handlePostVideo Success", responseData);
                        const { pictureId } = responseData;
                        resolve(pictureId);
                    },
                    (error: any) => {
                        console.log("handlePostVideo Fail", error);
                        reject(null);
                    }
                );
            });
        } catch (error) {
            console.error('Error in handlePostVideo:', error);
            return null;
        }
    };
    
    const handleClickSave = async (): Promise<void> => {
        try {
            setFormStep(4);

            setTimeout(() => {
                onModalHide();
            }, 2000);
            
            // Use a local variable to collect picture IDs
            const collectedPictureIds: string[] = [];
    
            // Process all images
            for (const file of newImageArray) {

                let compressedImage;
                let compressedVideo;

                if (file.type !== 'video/mp4') {
                    compressedImage = await handleImageCompression(file);
                    console.log('Image compression completed successfully', compressedImage);

                    const pictureId = await handlePostPicture(compressedImage);
                    if (pictureId) {
                        collectedPictureIds.push(pictureId);
                        console.log('Picture added', compressedImage);
                    }

                } else {
                    compressedVideo = await handleVideoCompression(file);
                    console.log('Video compression completed successfully', compressedVideo);

                    const pictureId = await handlePostVideo(compressedVideo);

                    console.log('PICTURE ID:', pictureId);
                    if (pictureId) {
                        collectedPictureIds.push(pictureId);
                        console.log('Video added', compressedVideo);
                    }
                }                
            }
    
            console.log("collectedPictureIds", collectedPictureIds);
    
            // After all images are processed, update the state with the collected picture IDs
            setFormStateImages(collectedPictureIds);
            setFormStateMemory((prevState) => ({
                ...prevState,
                pictureIds: collectedPictureIds,
            }));
    
            // Call handlePost to submit form data with the latest pictureIds
            await handlePost(collectedPictureIds);
    
            onFileUpload(false, false, newImageArray.length, newImageArray.length, null);
        } catch (error: any) {
            onFileUpload(false, false, newImageArray.length, newImageArray.length, error);
            console.error('An error occurred:', error);
        }
    };
    
    // Post form data to create Life Event
    const handlePost = async (pictureIds: string[]): Promise<void> => {
        try {
            // Here, instead of relying on the state, use the passed pictureIds
            const formData = {
                ...formStateMemory,
                pictureIds: pictureIds,
            };
    
            await postFormData(
                commonData.token,
                `memorial/${memorialData.memorialId}/memory`,
                formData,
                (response: any) => {
                    setFormSuccess("");
                    onFileUpload(false, false, newImageArray.length, newImageArray.length, null);
                },
                (error: any) => { // error
                    setFormSuccess(error);
                    onFileUpload(false, false, newImageArray.length, newImageArray.length, error);
                }
            );
    
            console.log('Form data posted with pictureIds:', pictureIds);
    
        } catch (error: any) {
            console.error('An error occurred while posting form data:', error);
            setFormSuccess('Failed to submit form data.');
            setFormStep(4);
        }
    };

    const inputs = document.querySelectorAll('textarea[data-expandable]');

    if (inputs) {
        inputs.forEach((textarea, i) => {
            textarea.addEventListener('input', function(e) {

                const textAreaInput = e.target as HTMLTextAreaElement;
                textAreaInput.style.removeProperty('height');
                textAreaInput.style.height = (textAreaInput.scrollHeight+2) + 'px';
            });
        });
    }

    return (
        <>
            <input ref={pictureInputRef} id="file" name="file" type="file" accept="image/*, video/*" onChange={handleFormChange} style={{display: "none"}} multiple />
            
            <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')} h-100 ${animation}`} style={{backgroundColor: "#00222F", zIndex: "999"}}>

                {formStep !== 4 &&
                    <div className="modal-header-bg">
                            
                        {formStep === 0 ?
                            <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBackClose}>
                                <img src="/images/modal-back-chev.svg" />
                            </div>
                            :
                            <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                                <img src="/images/modal-back-chev.svg" />
                            </div>
                        }
                        
                        {formStep === 3 && (addPhotos || addVideos) ?
                            <>
                                {addPhotos && <p className="modal-title">Add photos</p>}
                                {addVideos && <p className="modal-title">Add videos</p>}
                            </>
                            :
                            <p className="modal-title">New Memory</p>
                        }

                        <div className="btn btn-modal-close" onClick={handleClickBackClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                    </div>
                }
                
                <div className={`form-progress form-progress-absolute ${formProgressHide}`} style={{top: "100px"}}>
                    <img src={`/images/fl-progress-camera.svg`} className={`form-progress-current form-progress-${formStep}`} />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                </div>


                {/* Step 0 - Title */}
                <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">What’s this memory called?</p>

                    <div className="login-form">
                        <div className="mb-4">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input id="title" name="title" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter a short title for this event" />
                        </div>
                    </div>
                </div>

                {/* Step 1 - Message */}
                <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <div className="form-floating">
                        <textarea data-expandable ref={txtAreaRef} id="message" name="message" value={formStateMemory.message} onChange={handleFormChange} maxLength={240} className="form-control memory-message-input" placeholder="Write a description of the memory" />
                        <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                    </div>
                </div>

                {/* Step 2 - Media selection */}
                <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">Upload some photos or videos</p>

                    <div className="d-flex flex-column">
                        <div className="image-upload-menu-item" onClick={handleAddAssets}>
                            <div className="d-flex gap-3">
                                <img src="images/camera-icon.svg" width={24} />
                                <p className="event-add-assets">Add photos and videos</p>
                            </div>
                        </div>

                        <div className="image-upload-menu-item">
                            <div className="d-flex gap-3">
                                <img src="images/scan-icon.svg" width={24} />
                                <p className="event-add-assets">Scan a photo</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Step 3 - Final edit */}
                <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep < 3 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                    {addPhotos &&
                        <>
                            {newImageArray.length !== 0 ?
                                <>
                                    <div className="d-flex justify-content-between gap-3">
                                        {finalEdit ?
                                            <div className="login-form w-100">
                                                <input
                                                    id="title"
                                                    name="title"
                                                    type="text"
                                                    onChange={handleFormChange}
                                                    className="form-control"
                                                    value={formStateMemory.title}
                                                    placeholder="Enter a short title for this event"
                                                    style={{height: '76px', fontSize: '1.5rem'}}
                                                />
                                            </div>:
                                            <p className="modal-heading w-100">{truncate(formStateMemory.title)}</p>
                                        }
                                        
                                        <img src="/images/fl-btn-pen.svg" onClick={handleFinalEdit} width={32} height={32} style={{cursor: 'pointer'}} />
                                    </div>

                                    <div className="event-images-preview no-scrollbar gap-4">
                                        {buildImageArray()}
                                        <div className="event-additional-images final-screen" onClick={handleAddImage} style={{cursor: 'pointer'}}><img src="/images/fl-plus-lg.svg" /></div>
                                    </div>

                                    {finalEdit ?
                                        <div className="form-floating">
                                            <textarea
                                                ref={txtAreaFinalRef}
                                                data-expandable
                                                id="message"
                                                name="message"
                                                value={formStateMemory.message}
                                                onChange={handleFormChange}
                                                maxLength={240}
                                                className="form-control memory-message-input final-edit"
                                                placeholder="Write a description of the memory"
                                                style={{backgroundColor: 'rgba(255, 255, 255, 0.0509803922)', border: '2px solid #40626F'}}
                                            />
                                            <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                                        </div>:
                                        <p className="memory-preview-message">{formStateMemory.message}</p>
                                    }
                                    
                                    <button className={`btn fl-btn-modal-bottom`} onClick={handleClickSave} style={{position: "absolute", bottom: 16, width: "calc(100% - 32px)", zIndex: "999"}}>Save Memory</button>
                                </>
                                :
                                <>
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="add-photo-btn" onClick={handleAddImage}>
                                            <img src="/images/fl-plus-lg.svg" width={32} height={32} />
                                            <p>Click to upload photos</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }

                    
                </div> 

                {/* Step 4 - Complete */}
                <div className={`form-screen form-content form-content-${formStep === 4 ? 'current' : (formStep < 4 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                    <div className="d-flex flex-column h-100">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="modal-success-txt">
                                <p><b>Memory added</b></p>
                            </div>
                        </div>
                    </div>
                </div>

                {btnNext &&
                    <>
                        {formStep === 1 || formStep === 2 ?
                            <div className="fl-btn-group-modal-bottom d-flex gap-3">
                                <button className={`btn fl-btn-modal-bottom ${btnNextAnimation}`} onClick={handleClickNext}>Next</button>
                                <button className={`btn fl-btn-modal-bottom dark ${btnNextAnimation}`} onClick={handleClickNext}>Skip</button>
                            </div>
                            :
                            <div className="fl-btn-group-modal-bottom">
                                <button className={`btn fl-btn-modal-bottom ${btnNextAnimation}`} onClick={handleClickNext}>Next</button>
                            </div>
                        }
                    </>
                }
                {btnSkip &&
                    <>
                        <button className={`btn-modal-footer-skip ${btnSkipAnimation}`} onClick={handleSkip}>Skip</button>
                    </>
                }
            </div>            
        </>
    );
}