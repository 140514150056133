import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"

type ModalProps = {
    relative: string
    showModal: boolean
}

export const LivingPlaceholderPopup: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Modal states
    const [show, setShow] = useState(false);
    const [popupAnimation, setPopupAnimation] = useState<string>('');

    useEffect(() => {
        if (props.showModal) {
            handleShow();
        }
    }, [props.showModal])

    // On modal show
    const handleShow = () => {
        setShow(true);
        setPopupAnimation('animate__animated animate__slideInUp');
    }

    // On modal hide
    const handleClose = () => {
        setPopupAnimation('animate__animated animate__slideOutDown');
        setTimeout(() => {
            setShow(false);
        }, 1000);
    }

    return (
        <>
            <Modal className="modal-add-relative" show={show} animation={false} backdrop onHide={handleClose}>
                <Modal.Body>
                    <div className={`add-relative-popup ${popupAnimation}`}>
                        <div className="popup-close" onClick={handleClose}><div /></div>
                        <div className="d-flex flex-column gap-4">
                            <div className="add-deceased-relative-popup-txt d-flex flex-column gap-2">
                                <p>Invite {props.relative}</p>
                                <p>Allow {props.relative} to view your tree and the memorials on it with the invite link below.</p>
                            </div>

                            <div className="btn fl-btn-white">Create memorial</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}